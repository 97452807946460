import fitvids from 'fitvids'
//import $ from 'jquery'
//import magnificPopup from 'magnific-popup'
var $ = jQuery

export function initPopups() {

  //console.log('Init Popups');

  $('a.open-popup-link').magnificPopup({
    type:'inline',
    midClick: true,
    closeBtnInside:true,
    closeMarkup :
    `<button title="%title%" type="button" class="mfp-close">
      <svg xmlns="//www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 29.41 29.41">
        <polygon points="29.41 1.41 28 0 14.71 13.29 1.41 0 0 1.41 13.29 14.71 0 28 1.41 29.41 14.71 16.12 28 29.41 29.41 28 16.12 14.71 29.41 1.41"/>
      </svg>
    </button`,
    removalDelay: 800,
    mainClass: 'modalBuy',
    fixedContentPos:true,
  });

  $('a.popuplink').magnificPopup({
    type: 'ajax',
    removalDelay: 800,
    mainClass: 'mfp-animate',
    closeBtnInside:true,
    fixedContentPos:true,
    closeMarkup :
    `<button title="%title%" type="button" class="mfp-close">
      <svg xmlns="//www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 29.41 29.41">
        <polygon points="29.41 1.41 28 0 14.71 13.29 1.41 0 0 1.41 13.29 14.71 0 28 1.41 29.41 14.71 16.12 28 29.41 29.41 28 16.12 14.71 29.41 1.41"/>
      </svg>
    </button`,
    callbacks: {
      parseAjax: function(mfpResponse) {
        mfpResponse.data = $(mfpResponse.data).find('.ajaxcontent');
      },
      ajaxContentAdded: function() {
        history.pushState('', '', this.currItem.src);
        fitvids('.wswyg-content');
      },
      afterClose: function() {
        history.pushState('', '', siteURL);
      }
    }
  });

}
