import sniffer from 'sniffer'
import fitvids from 'fitvids'
import SplitText from './SplitText.min.js'
import Menu from './menu.js'
import {initPersonnages} from './personnages.js'
import {initAlbum} from './album.js'
import {initActus} from './actus.js'
import {initSpectacle} from './spectacle.js'
import {initPhotos} from './photos.js'
import {initVideos} from './videos.js'
import {initVideosLoops} from './videosloops.js'
import {initPopups} from './popups.js'
import {initParallax} from './parallax.js'
import {shuffleArray} from './utils.js'

const devMode = false;
const $body = $('body');
const $app = $('.app');
const $videoIntro = $("#videoIntro");
let aspectRatio;
let wW = $(window).width();

class App {

  constructor(opt = {}) {

    window.scrollTo(0,0);

    if (typeof(window.location.hash) !== 'undefined' && window.location.hash.length > 0) {
        window.location.hash = '';
    }

    console.log("%cCreated by wokine","color: #000; padding: 5px 0px;"),console.log("%chttp://wokine.com","color:#ccc")
    this.init();
    this.addEvents();

  }

  init() {

    FastClick.attach(document.body);

    const menu = new Menu();

    if($body.hasClass('home')){

      initActus();
      initSpectacle();
      initPersonnages();
      initAlbum();
      initPhotos();
      initVideos();
      initPopups();

      resizeVideo($videoIntro);
      this.intro();


      if (!sniffer.isPhone) initParallax();
      if (sniffer.isPhone) {
        this.intro();
        $videoIntro.remove();
      }


    } else {

      $body.removeClass('is-loading');
      $body.removeClass('showIntro');
      fitvids('.wswyg-content');

    }

  }

  addEvents() {

    $(window).on('beforeunload', function(){
        $(window).scrollTop(0);
    });

    $(window).on('resize', (e)=> {
      wW = $(window).width();
    });

    TweenMax.ticker.addEventListener("tick", render, this, true, 1);
    function render() {
      resizeVideo($videoIntro);
    }

    if(!$body.hasClass('home')) $(window).trigger('resize');

  }

  intro() {

    //console.log('intro');
    $body.removeClass('is-loading');
    window.scrollTo(0,0);
    const tl = new TimelineMax({ paused: true });
    let introSplitText = new SplitText("#intro p", {type:"words,chars"});
    let introChars = introSplitText.words;
    tl.from($('.intro-visuel'), 2, {opacity:0,  ease:Power3.easeOut}, 0)
    tl.staggerFrom(introChars, 1.6, {opacity:0, y:5,  ease:Power3.easeOut}, 0.02, 2)
    tl.add(() => {
      $body.removeClass('showIntro');
      /*$('.fastlinks .open-popup-link').delay(1200).queue(function(){
        $(this).trigger('click').dequeue();
      })*/
    })
    tl.restart();

  }

}

function resizeVideo(video) {

  var newWidth;
  var newHeight;
  var videoRatio = 1.7777777777777777;
  var parentWidth  = video.parent().width();
  var parentHeight = video.parent().height();
  var parentRatio = parentWidth / parentHeight;

  if (parentRatio > videoRatio && !video.hasClass('bgWidth')) {
    video.removeClass("bgHeight");
    video.addClass("bgWidth");
  }
  if (parentRatio <= videoRatio && !video.hasClass('bgHeight')) {
    video.removeClass("bgWidth");
    video.addClass("bgHeight");
  }

}


$(document).ready(function() {
  const app = new App();
});
