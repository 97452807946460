export function initVideos() {

  //console.log('Init Vidéos');

  let swiperVideosContainer = $('.swiper-videos');
  let swiperVideosPrev = $('.swiper-videos .swiper-button-prev');
  let swiperVideosNext = $('.swiper-videos .swiper-button-next');
  let swiperVideosPagination = $('.swiper-videos .swiper-pagination');
  let swiperVideos = new Swiper (swiperVideosContainer.find('.swiper-container'), {
    nextButton: swiperVideosNext,
    prevButton: swiperVideosPrev,
    pagination: swiperVideosPagination,
    paginationType: 'fraction',
    loop:true,
  });

  swiperVideos.on('onTransitionStart', function (swiper) {
      swiperVideosContainer.find('.swiper-container').addClass('animating');
  });

  swiperVideos.on('onTransitionEnd', function (swiper) {
      swiperVideosContainer.find('.swiper-container').removeClass('animating')
  });

}
