//import $ from 'jquery'
//import magnificPopup from 'magnific-popup'
var $ = jQuery

export function initPhotos() {

  //console.log('Init Photos');

  //Photos
  let swiperPhotosContainer = $('.swiper-photos');
  let swiperPhotosPrev = $('.swiper-photos .swiper-button-prev');
  let swiperPhotosNext = $('.swiper-photos .swiper-button-next');
  let swiperPhotosPagination = $('.swiper-photos .swiper-pagination');
  let swiperPhoto = new Swiper (swiperPhotosContainer.find('.swiper-container'), {
    nextButton: swiperPhotosNext,
    prevButton: swiperPhotosPrev,
    pagination: swiperPhotosPagination,
    paginationType: 'fraction',
    lazyLoading:true,
    loop:true,
    lazyLoadingInPrevNext:true,
    lazyLoadingInPrevNextAmount:2,
    onInit: function(swiper){

      swiperPhotosContainer.magnificPopup({
        delegate: 'a',
        type:'image',
        closeOnContentClick: true,
        closeBtnInside: false,
        fixedContentPos:true,
        removalDelay: 800,
        mainClass: 'mfp-zoom-img mfp-animate',
        closeMarkup :
        `<button title="%title%" type="button" class="mfp-close">
          <svg xmlns="//www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 29.41 29.41">
            <polygon points="29.41 1.41 28 0 14.71 13.29 1.41 0 0 1.41 13.29 14.71 0 28 1.41 29.41 14.71 16.12 28 29.41 29.41 28 16.12 14.71 29.41 1.41"/>
          </svg>
        </button`,
        callbacks: {
          imageLoadComplete: function() {
            var imageH = $(this.content).outerHeight();
            var wH = window.innerHeight;
            if (imageH > wH) {
              console.log(imageH, wH);
              TweenLite.to($(this.container).parent(), 1.6, {scrollTo: {y:(imageH- wH) * 0.5, autoKill:true }, ease: Power3.easeInOut});
            }
          }
        }
      });

    }

  });

  swiperPhoto.on('onTransitionStart', function (swiper) {
      swiperPhotosContainer.find('.swiper-container').addClass('animating');
  });

  swiperPhoto.on('onTransitionEnd', function (swiper) {
      swiperPhotosContainer.find('.swiper-container').removeClass('animating')
  });



}
