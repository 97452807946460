//import $ from 'jquery'
//import magnificPopup from 'magnific-popup'
var $ = jQuery

export function initPersonnages() {

  //console.log('Init Personnages');

  //Personnages
  let swiperPersonnagesContainer = $('.swiper-personnages');
  let swiperPersonnagesPrev = $('.swiper-personnages .swiper-button-prev');
  let swiperPersonnagesNext = $('.swiper-personnages .swiper-button-next');
  let swiperPersonnages = new Swiper (swiperPersonnagesContainer.find('.swiper-container'), {

    nextButton: swiperPersonnagesNext,
    prevButton: swiperPersonnagesPrev,
    lazyLoading:true,
    lazyLoadingInPrevNext:true,
    lazyLoadingInPrevNextAmount:4,
    loop:false,
    slidesPerView:'auto',
    breakpoints: {
      640 : {
        lazyLoadingInPrevNextAmount:1,
      }
    },
    onInit: function(swiper){

    /*  swiperPersonnagesContainer.magnificPopup({
        delegate: 'a',
        type: 'image',
        closeOnContentClick: true,
        closeBtnInside: false,
        fixedContentPos:true,
        removalDelay: 800,
        mainClass: 'mfp-zoom-img mfp-animate',
        closeMarkup :
        `<button title="%title%" type="button" class="mfp-close">
          <svg xmlns="//www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 29.41 29.41">
            <polygon points="29.41 1.41 28 0 14.71 13.29 1.41 0 0 1.41 13.29 14.71 0 28 1.41 29.41 14.71 16.12 28 29.41 29.41 28 16.12 14.71 29.41 1.41"/>
          </svg>
        </button`,
        callbacks: {

        }
      });*/

    }

  });

  swiperPersonnages.on('onTransitionStart', function (swiper) {
      swiperPersonnagesContainer.find('.swiper-container').addClass('animating');
  });

  swiperPersonnages.on('onTransitionEnd', function (swiper) {
      swiperPersonnagesContainer.find('.swiper-container').removeClass('animating')
  });



}
