import MenuSpy from 'menuspy'

let wW = $(window).width();

class Menu {

  constructor(opt = {}) {

    //console.log('init Menu');
    this.init()

  }

  init() {

    $('a.brand').on('click', (e)=> {
      e.preventDefault();
      this.slideTo('#intro');
    })


    $('.menu-toggle').on('click', (e)=> {

      e.preventDefault();

      if (!$('body').hasClass('showMenu')) {
        this.openMenu();
      } else {
        this.closeMenu();
      }

    })


    $('.menuFade').on('click', (e)=> {
      e.preventDefault();
      this.closeMenu();
    })

    //Slide Anchors

    $('.menu-container .navbar a').on('click', (e)=> {

      if ($(e.currentTarget).hasClass('hashtag')) return;
      e.preventDefault();

      $('.menu-container .navbar li.active').removeClass('active');
      let link = $(e.currentTarget);
      link.parent().addClass('active');
      let target = link.attr("href");
      let offset = link.data('offset');
      this.slideTo(target, offset);

    })

    var menuContainer = document.querySelector('#mainHeader');
    var menuSpy = new MenuSpy(menuContainer);

  }

  openMenu() {
    $('body').addClass('showMenu');
  }

  closeMenu() {
    $('body').removeClass('showMenu');
  }

  slideTo(target , offset = 0) {
    let anchorPos = $(target).offset().top + offset;
    TweenLite.to(window, 1.6, {scrollTo: {y:anchorPos , autoKill:false }, ease: Power3.easeInOut});
    $('body').removeClass('showMenu');

  }

}

export default Menu;
