export function initActus() {

  //console.log('Init Actus');

  //Photos
  let swiperActusContainer = $('.swiper-actus');
  let swiperActusPrev = $('.swiper-actus .swiper-button-prev');
  let swiperActusNext = $('.swiper-actus .swiper-button-next');
  let swiperActus = new Swiper (swiperActusContainer.find('.swiper-container'), {
    slidesPerView : 4,
    spaceBetween : 10,
    lazyLoading:true,
    nextButton: swiperActusNext,
    prevButton: swiperActusPrev,
    loop:false,
    lazyLoadingInPrevNext:true,
    breakpoints: {
      640 : {
        slidesPerView : 1
      },
      768 : {
        slidesPerView : 2
      },
      1200 : {
        slidesPerView : 3
      }
    }
  });

  //console.log('Init press');

  //Photos
  let swiperPressContainer = $('.swiper-press');
  let swiperPressPrev = $('.swiper-press .swiper-button-prev');
  let swiperPressNext = $('.swiper-press .swiper-button-next');
  let swiperPress = new Swiper (swiperPressContainer.find('.swiper-container'), {
    slidesPerView : 3,
    spaceBetween : 5,
    nextButton: swiperPressNext,
    prevButton: swiperPressPrev,
    lazyLoading:true,
    loop:false,
    lazyLoadingInPrevNext:true,
    breakpoints: {
      640 : {
        slidesPerView : 1
      },
      768 : {
        slidesPerView : 2
      },
    }
  });

}
