import SplitText from './SplitText.min.js'
import {shuffleArray} from './utils.js'

export function initParallax() {

  //console.log('Init Parallax');

  let sy;
  //OnScroll
  window.addEventListener('scroll', scroll);
  function scroll() {
    sy = window.pageYOffset;
  }

  $('.animated').viewportChecker({
    repeat:true,
  })

  //Intro
  const tlIntro = new TimelineMax({ paused: true});
  let introMin = 0;
  let introMax = $('#intro').outerHeight();
  tlIntro.to($('#intro .container'), 1, { y:160 }, 0)
  tlIntro.to($('#intro .intro-visuel'), 1, { y:110 }, 0)

  //Spectacle
  const tlSpectacles = new TimelineMax({ paused: true});
  let spectacleMin = $('#Spectacle .visuels').offset().top - window.innerHeight;
  let spectaclMax = $('#Spectacle .visuels').offset().top + $('#Spectacle .visuels').outerHeight();
  TweenLite.set($('#Spectacle .visuel-history img'), {y:"-20%", scale:1.1});
  TweenLite.set($('#Spectacle .visuel-genese img'), {y:"-20%", scale:1.1});
  tlSpectacles.to($('#Spectacle .visuel-history img'), 1, { y:"20%", ease:Power0.easeNone}, 0);
  tlSpectacles.to($('#Spectacle .visuel-genese img'), 1, { y:"20%", ease:Power0.easeNone}, 0);

  //Album
  const tlAlbumBackground = new TimelineMax({ paused: true});
  let albumMin = $('#Album .teasing').offset().top - window.innerHeight;
  let albumMax = $('#Album .album').offset().top;
  TweenLite.set($('#Album > .background .visuel'), {y:"-20%"});
  tlAlbumBackground.to($('#Album > .background .visuel'), 1, { y:"20%", ease:Power0.easeNone}, 0);

  //Media
  const tlMedias = new TimelineMax({ paused: true});
  TweenLite.set($('#Medias .photos'), {y:-100});
  TweenLite.set($('#Medias .videos'), {y:100});
  let mediasMin = $('#Medias').offset().top - window.innerHeight;
  let mediasMax = $('#Medias').offset().top + $('#Medias').outerHeight();
  tlMedias.to($('#Medias .photos'), 1, { y:100 }, 0)
  tlMedias.to($('#Medias .videos'), 1, { y:-100 }, 0)

  //Blockquote
  const tlQuote = new TimelineMax({ paused: true});
  let quoteSplitText = new SplitText("#Quote blockquote", {type:"words,chars"});
  let quoteChars = quoteSplitText.words;
  tlQuote.staggerFrom(quoteChars, 1.6, {opacity:0, y:50,  ease:Power3.easeOut}, 0.02, "+=0");
  $('#Quote').viewportChecker({
    callbackFunction: function(elem, action){
      tlQuote.restart();
    }
  })

  //OnResize
  window.addEventListener('resize', updatePos);
  function updatePos() {
    introMax = $('#intro').outerHeight();
    mediasMin = $('#Medias').offset().top - window.innerHeight;
    mediasMax = $('#Medias').offset().top + $('#Medias').outerHeight();
    spectacleMin = $('#Spectacle').offset().top - window.innerHeight;
    spectaclMax = $('#Spectacle').offset().top + $('#Spectacle').outerHeight() + window.innerHeight;
    albumMin = $('#Album').offset().top - window.innerHeight;
    albumMax = $('#Album').offset().top + window.innerHeight;
  }

  //OnRender
  TweenMax.ticker.addEventListener("tick", render, this, true, 1);
  function render() {

    //intro
    let normIntro = clamp(normalize(sy, introMin, introMax), 0, 1);
    tlIntro.progress(normIntro);

    //spectacle
    let normSpectacle = clamp(normalize(sy, spectacleMin, spectaclMax), 0, 1);
    tlSpectacles.progress(normSpectacle);

    //album
    let normAlbum = clamp(normalize(sy, albumMin, albumMax), 0, 1);
    tlAlbumBackground.progress(normAlbum);

    //media
    let normMedia = clamp(normalize(sy, mediasMin, mediasMax), 0, 1);
    tlMedias.progress(normMedia);

  }

  function normalize(value, min, max) {
  	return (value - min) / (max - min);
  }

  function clamp(value, min, max) {
  	return value < min ? min : (value > max ? max : value);
  }

}
