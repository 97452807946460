//import $ from 'jquery'
//import magnificPopup from 'magnific-popup'
var $ = jQuery

export function initSpectacle() {

  //console.log('Init Spectacle');

  $('#Spectacle .nav-tabs a').on('click', function(e){

    e.preventDefault();

    $('#Spectacle .tabs-container .active').removeClass('active');
    $('#Spectacle .nav-tabs .active').removeClass('active');
    $('#Spectacle .visuels-container .active').removeClass('active');

    let target = $(this).attr('href');

    //move Line
    $('#Spectacle .nav-tabs .line').css({
      left:$(this).position().left,
      width:$(this).width()
    })

    //set Actives
    $(this).addClass('active');
    $(target).addClass('active');

    switch (target) {
      case "#Genese":
        $('#Spectacle .visuels-container .visuel-genese').addClass('active');
        break;
      case "#History":
        $('#Spectacle .visuels-container .visuel-history').addClass('active');
        break;
      case "#Team":
        $('#Spectacle .visuels-container .visuel-team').addClass('active');
        break;
      }

  })

  $('#Spectacle .nav-tabs li:first-child a').trigger('click');


}
